;(function () {
   'use strict'

   angular.module('internalWebsiteApp').directive('tripManager', Directive)

   function Directive() {
      return {
         restrict: 'E',
         templateUrl: 'modules/logistics/Directives/tripManager/tripManager.html',
         scope: true,
         controller: Controller,
      }
   }

   function Controller(
      $rootScope,
      $scope,
      $q,
      $window,
      $filter,
      logisticsData,
      util,
      alertService,
      $location,
      $uibModal
   ) {
      var _logisticsRootState = $scope.logisticsRootState
      var _luxonDT = $window.luxon.DateTime
      var _cantSetEstimatedBeforeDeliveryStartErrorMsg =
         "A drop's Estimated Delivery cannot be earlier than its trip's Delivery Start."
      var idIfNoTruckload = 0
      var _localState = {
         busy: false,
         busyString: undefined,
         showingAddTripUi: false,
         newTripsToAdd: [],
         dateRangeOptions: {
            showWeeks: false,
            startingDay: 0,
         },
         datepickerOptionsDefault: {
            showWeeks: false,
            startingDay: 0,
         },
         timeOptions: {
            readonlyInput: false,
            showMeridian: true,
         },
         loadingTripStops: false,
         currentTrip: undefined,
         rangePickerOpen: false,
         // ----------------------------------------------------------------------------
         // Trip filter range
         // ----------------------------------------------------------------------------
         tripFilterRange: {
            cutoffBeforeDisplay: $location.search().filterBefore ? new Date($location.search().filterBefore) : '',
            cutoffBeforeInput: undefined,
            cutoffAfterDisplay: $location.search().filterAfter
               ? new Date(removeTimeFromIsoString($location.search().filterAfter))
               : _luxonDT.local().minus({months: 1}).toJSDate(),
            cutoffAfterInput: undefined,
         },
         // ----------------------------------------------------------------------------
         // Trips table state
         // ----------------------------------------------------------------------------
         tableState: {
            // values are initial
            sortedBy: 'cutoff',
            sortedReverse: false,
         },
         // ----------------------------------------------------------------------------
         // Stops table state
         // ----------------------------------------------------------------------------
         nestedTableState: {
            // values are initial
            sortedBy: undefined,
            sortedReverse: false,
         },
         // ----------------------------------------------------------------------------
         // Bulk adjust
         // ----------------------------------------------------------------------------
         uiShowBulkAdjust: false,
         bulkAdjustForTruckloadId: null,
         bulkAdjustInputs: {
            days: 0,
            hours: 0,
            minutes: 0,
         },
      }
      $scope.localState = _localState

      $scope.finalizedDeliveryFlagsBySlug = {
         driverWillCall: {
            long: 'Driver Will Call',
            short: 'DWC',
         },
         unknownDelay: {
            long: 'Unknown Delay',
            short: 'UD',
         },
      }

      var _carrierTypeOptions = [
         // Note that the `value` property values here match their backend equivalents
         {label: 'Azure Standard', value: 2},
         {label: 'Drive Azure', value: 1},
         {label: 'Contractor', value: 3},
      ]

      // Note that the backend currently only allows `actualDelivery` to be clearable.
      var _clearablePropsWhitelist = ['actualDelivery']

      //================================================================================
      // Helpers
      //================================================================================

      $scope.getToday = function () {
         return new Date()
      }

      $scope.back1Month = function (jsDateObj) {
         return _luxonDT.fromJSDate(jsDateObj).minus({months: 1}).toJSDate()
      }

      $scope.forward1Month = function (jsDateObj) {
         return _luxonDT.fromJSDate(jsDateObj).plus({months: 1}).toJSDate()
      }

      $scope.isDateInPast = function (isoDate) {
         var now = new Date()
         return now.toISOString() > isoDate
      }

      $scope.getCarrierTypeLabelById = function (id) {
         var carrierTypeOption = util.findByPropertyValue(_carrierTypeOptions, 'value', id)
         if (carrierTypeOption) {
            return carrierTypeOption.label
         }
      }

      function setBusyTrue(busyString) {
         closeOpenPopover()
         _localState.busy = true
         _localState.busyString = busyString
      }

      function setBusyFalse() {
         _localState.busy = false
         _localState.busyString = undefined
      }

      function closeOpenPopover() {
         angular.element(document.querySelector('body')).click()
      }

      function captureAllSettledErrors(results) {
         return results.filter(function (result) {
            if (result.state === 'rejected') {
               return result
            }
         })
      }

      function convertToIsoInHqTimezone(srcIso) {
         return _luxonDT.fromISO(srcIso, {zone: 'America/Los_Angeles'}).toISO()
      }

      function removeTimeFromIsoString(isoString) {
         if (isoString) {
            return isoString.slice(0, 10)
         }
      }

      //================================================================================
      // Range filter
      //================================================================================

      $scope.handleTripRangerFilterOpen = function () {
         _localState.rangePickerOpen = true

         // Ensure that the input value is set to the display value on open
         if (!_localState.tripFilterRange.cutoffAfterInput) {
            _localState.tripFilterRange.cutoffAfterInput = _localState.tripFilterRange.cutoffAfterDisplay
         }
         if (!_localState.tripFilterRange.cutoffBeforeInput) {
            _localState.tripFilterRange.cutoffBeforeInput = _localState.tripFilterRange.cutoffBeforeDisplay
         }
      }

      $scope.handleTripRangeFilterCancel = function () {
         _localState.rangePickerOpen = false
         // reset inputs
         _localState.tripFilterRange.cutoffAfterInput = _localState.tripFilterRange.cutoffAfterDisplay
         _localState.tripFilterRange.cutoffBeforeInput = _localState.tripFilterRange.cutoffBeforeDisplay
      }

      $scope.handleTripRangeFilterSubmit = function () {
         _localState.rangePickerOpen = false

         var after = _luxonDT.fromJSDate(_localState.tripFilterRange.cutoffAfterInput)
         var before = _luxonDT.fromJSDate(_localState.tripFilterRange.cutoffBeforeInput)

         if (after.isValid) {
            _localState.tripFilterRange.cutoffAfterDisplay = _localState.tripFilterRange.cutoffAfterInput
            $location.search('filterAfter', removeTimeFromIsoString(after.toISO()))
         }

         if (before.isValid) {
            _localState.tripFilterRange.cutoffBeforeDisplay = _localState.tripFilterRange.cutoffBeforeInput
            $location.search('filterBefore', removeTimeFromIsoString(before.toISO()))
         } else {
            _localState.tripFilterRange.cutoffBeforeDisplay = null
            $location.search('filterBefore', null)
         }

         loadCurrentRouteTrips()
      }

      $scope.focusAllFutureInput = function () {
         _localState.tripFilterRange.cutoffBeforeInput = $scope.getToday()
         $window.setTimeout(function () {
            document.getElementById('js-tripRangeFilterBefore').focus()
         })
      }

      //================================================================================
      // Column Sorting
      //================================================================================

      function sortTable(tableState, key) {
         function sort(key) {
            if (key === tableState.sortedBy) {
               tableState.sortedReverse = !tableState.sortedReverse
            } else {
               tableState.sortedBy = key
               tableState.sortedReverse = false
            }
         }
         sort(key)
      }

      $scope.sortTripsTable = function (key) {
         sortTable(_localState.tableState, key)
      }

      $scope.sortStopsTable = function (key) {
         sortTable(_localState.nestedTableState, key)
      }

      //================================================================================
      // Bulk Adjust
      //================================================================================

      $scope.openBulkAdjustUi = function (groupId) {
         _localState.uiShowBulkAdjust = true
         _localState.bulkAdjustForTruckloadId = groupId

         if (
            _localState.currentTrip.selectedStops.length > 1 &&
            _localState.currentTrip.selectedStops.length < _localState.currentTrip.stops.length
         ) {
            setAdjustOffsetToCustom()
         } else {
            _localState.currentTrip.selectAllInGroup(groupId)
            // Set offset to first
            var selectedStopsSorted = util.sortByProperty(
               _localState.nestedTableState.sortedBy,
               _localState.currentTrip.selectedStops
            )
            _localState.bulkAdjustStartingAtStop = selectedStopsSorted[0]
         }

         if (
            (_localState.currentTrip.isUiGroupedByTruckload &&
               _localState.currentTrip.stopsByTruckloadId[groupId].length) ||
            (!_localState.currentTrip.isUiGroupedByTruckload && _localState.currentTrip.allStopsSelected)
         ) {
            _localState.bulkAdjustShowAdjustFrom = true
         } else {
            // Note: Explicitly setting this to `false` is necessary because it's possible that it could have a cached value of `true`
            _localState.bulkAdjustShowAdjustFrom = false
         }
      }

      function closeBulkAdjustUi() {
         _localState.uiShowBulkAdjust = false
         // Handle checkboxes
         _localState.currentTrip.deselectAllStopsInAllGroups()
      }

      function resetBulkAdjust() {
         _localState.bulkAdjustInputs.days = 0
         _localState.bulkAdjustInputs.hours = 0
         _localState.bulkAdjustInputs.minutes = 0
      }

      $scope.cancelBulkAdjustUi = function () {
         closeBulkAdjustUi()
         resetBulkAdjust()
      }

      $scope.applyBulkAdjustUi = function () {
         // If no changes, exit
         if (
            _localState.bulkAdjustInputs.days === 0 &&
            _localState.bulkAdjustInputs.hours === 0 &&
            _localState.bulkAdjustInputs.minutes === 0
         ) {
            closeBulkAdjustUi()
            return
         }

         var isFutureTrip = _localState.currentTrip.status === 'future'
         var timeProp = isFutureTrip ? 'estimatedDelivery' : 'finalizedDelivery'

         function getAdjustedDate(stop) {
            return _luxonDT
               .fromISO(stop.display[timeProp].isoString, getLuxonOptions(stop.timezone))
               .set({seconds: 0, milliseconds: 0})
               .plus(_localState.bulkAdjustInputs)
               .toISO()
         }

         function getEarliestDrop() {
            var sortedDrops = util
               .sortByProperty(timeProp, _localState.currentTrip.selectedStops)
               .filter(function (stop) {
                  return stop.drop // Filter out pickups
               })
            return sortedDrops[0]
         }

         // Guard to prevent setting any drop's estimated or finalized delivery earlier than its trip's delivery start.
         if (isFutureTrip) {
            var earliestDrop = getEarliestDrop()
            if (_localState.currentTrip['delivery-start'] > getAdjustedDate(earliestDrop)) {
               return alertService.errorMessage(_cantSetEstimatedBeforeDeliveryStartErrorMsg)
            }
         }

         // Handle applying changes
         _localState.currentTrip.selectedStops.forEach(function (stop) {
            if (stop.selectedInUi) {
               stop.display[timeProp].isoString = getAdjustedDate(stop)
               stop.display[timeProp].isoStringHq = convertToIsoInHqTimezone(stop.display[timeProp].isoString)
               stop.dateCellUpdateEditState(timeProp, true)
            }
         })

         closeBulkAdjustUi()
         resetBulkAdjust()
      }

      $scope.onChangeOfBulkAdjustOffsetSelect = function (groupId) {
         if (!_localState.bulkAdjustStartingAtStop) {
            // Apply to all
            _localState.currentTrip.selectAllStopsInAllGroups()
         } else {
            var stopsToSort
            if (_localState.currentTrip.isUiGroupedByTruckload) {
               stopsToSort = _localState.currentTrip.stopsByTruckloadId[groupId]
            } else {
               stopsToSort = _localState.currentTrip.stops
            }

            // This ensures that the selected items will be contiguous (i.e. that the expected items will be selected)
            var listForBulkAdjusting = util.sortByProperty(_localState.nestedTableState.sortedBy, stopsToSort)

            if (_localState.nestedTableState.sortedReverse) {
               listForBulkAdjusting.reverse()
            }

            // 1. Get the index of the "starting at offset"
            // 2. unselect all those before it,
            // 3. ensure it and all those after it are selected.

            // 1.
            var startingAtIndex =
               _localState.bulkAdjustStartingAtStop &&
               util.findIndexByPropertyValue(listForBulkAdjusting, 'id', _localState.bulkAdjustStartingAtStop.id)

            // 2.
            for (var removeI = 0; removeI < startingAtIndex; removeI++) {
               _localState.currentTrip.deselectStopInUi(listForBulkAdjusting[removeI])
            }

            // 3.
            for (var addI = startingAtIndex; addI < listForBulkAdjusting.length; addI++) {
               _localState.currentTrip.selectStopInUi(listForBulkAdjusting[addI])
            }
         }
      }

      function setAdjustOffsetToFirst() {
         _localState.bulkAdjustStartingAtStop = _localState.currentTrip.selectedStops[0]
      }

      function setAdjustOffsetToCustom() {
         _localState.bulkAdjustStartingAtStop = null
      }

      $scope.bulkAdjustSelectFormatter = function (stop) {
         var timeProp
         if (
            _localState.nestedTableState.sortedBy === 'estimatedDeliveryHq' ||
            _localState.nestedTableState.sortedBy === 'finalizedDeliveryHq' ||
            _localState.nestedTableState.sortedBy === 'actualDeliveryHq'
         ) {
            timeProp = _localState.nestedTableState.sortedBy.replace('Hq', '')
         } else {
            timeProp = _localState.currentTrip.status === 'future' ? 'estimatedDelivery' : 'finalizedDelivery'
         }
         var name = stop.drop ? stop.drop.name : stop.pickup.name
         return (
            $filter('date')(stop.display[timeProp].isoString, 'MM/dd/y hh:mm a ', stop.display[timeProp].tzOffsetHrs) +
            stop.display[timeProp].tzShort +
            ' – ' +
            name
         )
      }

      //================================================================================
      //  Trip & Stop datetime picker common handlers
      //================================================================================

      $scope.closeDateTimePicker = function () {
         closeOpenPopover()
      }

      $scope.keyPressOpenDateTimePicker = function ($event) {
         if ($event.target.getAttribute('disabled')) {
            return
         }
         $event.target.click()
      }

      function setTimeDisplayObject(isoDateString, maybeTimezone) {
         var luxonOptions = {}
         if (maybeTimezone) {
            luxonOptions.zone = maybeTimezone
         }

         var luxonFromIso = _luxonDT.fromISO(isoDateString, luxonOptions)
         var luxonToIso = luxonFromIso.toISO()
         var luxonFromIsoHq = _luxonDT.fromISO(isoDateString, {zone: 'America/Los_Angeles'})
         var luxonToIsoHq = luxonFromIsoHq.toISO()

         return {
            isoString: isoDateString,
            tzShort: luxonFromIso.offsetNameShort,
            tzOffsetHrs: luxonToIso && luxonToIso.substr(luxonToIso.length - 6), // the hours timezone value of the ISO string (e.g. `-08:00`)
            isoStringHq: luxonToIsoHq,
            tzShortHq: luxonFromIsoHq.offsetNameShort,
         }
      }

      function getLuxonOptions(tripOrStop) {
         var luxonOptions = {}
         if (tripOrStop && tripOrStop.timezone) {
            luxonOptions.zone = tripOrStop.timezone
         }
         return luxonOptions
      }

      function getDisplayTimestampAsLuxonObj(tripOrStop, prop) {
         return _luxonDT.fromISO(tripOrStop.display[prop].isoString, getLuxonOptions(tripOrStop))
      }

      function getSavedTimestampAsLuxonObj(tripOrStop, prop) {
         return _luxonDT.fromISO(tripOrStop[prop], getLuxonOptions(tripOrStop))
      }

      var _dateTimeHandlerPrototype = {
         dateCellPopoverOpenHandler: function (propName) {
            this.propInEditMode = propName

            // Exit if this property's datetime picker is already open
            if (this.display[this.propInEditMode].datetimePickerIsOpen) {
               return
            }

            // Set the Datepicker's options
            this.datepickerOptions = angular.extend({}, _localState.datepickerOptionsDefault)

            // Do not allow Estimated Delivery to be set to a value earlier than its trip's Delivery Start, unless it's a pickup.
            // Note: A limitation of `uib-datepicker` is that this does not take time into consideration.
            if (this.propInEditMode === 'estimatedDelivery' && !this.pickup) {
               this.datepickerOptions.minDate = new Date(_localState.currentTrip['delivery-start'])
            }
            // Do not allow setting a trip's Delivery Start date to a value later than its earliest drop's Estimated Delivery
            // Note: A limitation of `uib-datepicker` is that this does not take time into consideration.
            else if (this.propInEditMode === 'delivery-start' && this.earliestDrop) {
               this.datepickerOptions.maxDate = new Date(this.earliestDrop.estimatedDelivery)
            }

            // Do not allow dates in the past
            if (!this.datepickerOptions.minDate && this.propInEditMode !== 'actualDelivery') {
               this.datepickerOptions.minDate = new Date()
            }

            $window.setTimeout(function () {
               var dateTimeCellInput = document.getElementById('js-popoverTimeInput')
               if (dateTimeCellInput) {
                  dateTimeCellInput.focus()
               }
            }, 200)

            this.editingJsDate = getDisplayTimestampAsLuxonObj(this, this.propInEditMode).toJSDate()

            // Handle needing to set a display value on opening of the datetime picker.
            // This is needed for the `finalizedDelivery` and `actualDelivery` fields.
            if (this.display[this.propInEditMode].needsToBeSet) {
               this.dateCellUpdater()
               this.display[this.propInEditMode].needsToBeSet = false
            }
         },
         dateCellUpdater: function () {
            var nowIsoDate = _luxonDT
               .fromJSDate(new Date(), getLuxonOptions(this))
               .set({seconds: 0, milliseconds: 0})
               .toISO()
            // The value to update the cell to. Convert the JS date object to an ISO string.
            var updateTo = _luxonDT
               .fromJSDate(this.editingJsDate, getLuxonOptions(this))
               .set({seconds: 0, milliseconds: 0})
               .toISO()

            // Do not allow Estimated Delivery to be set to a value earlier than its trip's Delivery Start, unless it's a pickup.
            // And, do not allow a trip's Delivery Start to be set to a value later than its earliest drop's Estimated Delivery.
            // Note: This is necessary because:
            //   - the `minDate` and `maxDate` properties on `datePickerOptions.minDate` do not take
            //   time into consideration (docs: https://angular-ui.github.io/bootstrap/#datepicker)
            //   - it's possible to update a datetime via the cell (i.e. bypass the datepicker)

            var tryingToSetEstimatedBeforeDeliveryStart =
               this.propInEditMode === 'estimatedDelivery' &&
               removeTimeFromIsoString(_localState.currentTrip['delivery-start']) > removeTimeFromIsoString(updateTo) &&
               !this.pickup
            var tryingToSetDeliveryStartAfterEstimated =
               this.propInEditMode === 'delivery-start' &&
               this.earliestDrop &&
               removeTimeFromIsoString(updateTo) > removeTimeFromIsoString(this.earliestDrop.estimatedDelivery)

            if (tryingToSetEstimatedBeforeDeliveryStart || tryingToSetDeliveryStartAfterEstimated) {
               // Effectively prevent the date's value from changing in the UI
               this.editingJsDate = getDisplayTimestampAsLuxonObj(this, this.propInEditMode).toJSDate()

               var errMsg = tryingToSetEstimatedBeforeDeliveryStart
                  ? _cantSetEstimatedBeforeDeliveryStartErrorMsg
                  : "<div class='_mb05'>A trip's Delivery Start cannot be later than its earliest drop's Estimated Delivery.</div><div>First adjust the Estimated Delivery dates/times for this trip's drops.</div>"

               return alertService.errorMessage(errMsg)
            }
            // Prevent setting a time in the past
            else if (
               this.propInEditMode !== 'actualDelivery' &&
               removeTimeFromIsoString(nowIsoDate) > removeTimeFromIsoString(updateTo)
            ) {
               // Effectively prevent the date's value from changing in the UI
               this.editingJsDate = getDisplayTimestampAsLuxonObj(this, this.propInEditMode).toJSDate()

               return alertService.warning({
                  message: 'This date and time may only be set to a point in the future.',
                  autoClose: true,
                  closeBtn: false,
               })
            }

            // Update the value
            this.display[this.propInEditMode].isoString = updateTo
            this.display[this.propInEditMode].isoStringHq = convertToIsoInHqTimezone(updateTo)

            this.dateCellUpdateEditState(this.propInEditMode)
         },
         dateCellUpdateEditState: function (prop, bypassNoChangeCheck) {
            function maybeSetEdited(tripOrStop) {
               // Set edit state if not already set
               if (tripOrStop.unsavedEdits.indexOf(prop) === -1) {
                  tripOrStop.unsavedEdits.push(prop)
                  tripOrStop.updateEditsCount()
               }
            }

            if (bypassNoChangeCheck) {
               return maybeSetEdited(this)
            }

            // -------------------------------------------------------------------------------------
            // Set or unset edited state
            // Note: Luxon adds milliseconds to the iso string, whereas the values coming from the API do not have milliseconds.
            // This is the reason for using Luxon's `equals` method for checking date equality.

            var actualValueLuxon = getSavedTimestampAsLuxonObj(this, prop)
            var displayValueLuxon = getDisplayTimestampAsLuxonObj(this, prop)

            // If no change, reset state to unedited.
            if (actualValueLuxon.equals(displayValueLuxon)) {
               this.dateCellResetSingle(prop)
            }
            // Otherwise, maybe set the state
            else {
               maybeSetEdited(this)
            }
         },
         dateCellResetSingle: function (prop, shouldClosePopover) {
            // Reset display value
            this.display[prop].isoString = this[prop]

            if (shouldClosePopover) {
               closeOpenPopover()
            } else {
               // Reset picker
               this.editingJsDate = getSavedTimestampAsLuxonObj(this, prop).toJSDate()
            }

            var unsavedEditIndex = this.unsavedEdits.indexOf(prop)
            if (unsavedEditIndex > -1) {
               this.unsavedEdits.splice(unsavedEditIndex, 1)
            }

            // If the property has yet to be set, the display object needs to be recreated.
            if (this.display[prop].hasOwnProperty('needsToBeSet')) {
               this.createDisplayObject()
            }

            this.updateEditsCount()
         },
         dateCellResetMultiple: function () {
            this.createDisplayObject()
            this.unsavedEdits = []
         },
         shouldShowClearButton: function (prop) {
            if (!this[prop] || !_clearablePropsWhitelist.includes(prop)) {
               return false
            }
            // Do not allow clearing the actual delivery if the delivery completed at has been set.
            if (prop === 'actualDelivery' && this.deliveryCompletedAt) {
               return false
            }
            return true
         },
         dateCellClearSingle: function (prop) {
            // Guard this to ensure that the property is allowed to be cleared.
            // Note that although in theory this should never evaluate to `true` because we're only showing the button
            // for this on relevant properties it still seems like a good idea to guard for it here.
            if (!_clearablePropsWhitelist.includes(prop)) {
               return
            }

            this.display[prop].needsToBeSet = true

            closeOpenPopover()

            if (!this.unsavedEdits.includes(prop)) {
               this.unsavedEdits.push(prop)
            }

            this.updateEditsCount()
         },
         updateEditsCount: function () {
            // If we're editing a stop...
            if (_logisticsRootState.params.trip) {
               updateStopEditsCount()
            }
            // Else, update the trip edits count
            else {
               updateTripEditsCount()
            }
         },
      }

      //================================================================================
      // Trips (core handling)
      //================================================================================

      var _tripViewPrototype = {
         createDisplayObject: function () {
            var display = {}
            display.datetimePickerIsOpen = false
            display.cutoff = setTimeDisplayObject(this.cutoff)
            display['pick-date'] = setTimeDisplayObject(this['pick-date'])
            display['delivery-start'] = setTimeDisplayObject(this['delivery-start'])
            display['delivery-end'] = setTimeDisplayObject(this['delivery-end'])
            display['warehouse-arrival'] = setTimeDisplayObject(this['warehouse-arrival'])
            this.display = display
         },
         openUi: function () {
            if (!$scope.hasPermission('read-stop')) {
               return
            }

            var _this = this

            _this.isTripUiExpanded = true
            _logisticsRootState.statusOfToggledOpenTrip = _this.status

            loadTripStops(_this, true).then(function () {
               // Set the sort order of the stop table(s)
               if (_this.status === 'future') {
                  _localState.nestedTableState.sortedBy = 'estimatedDeliveryHq'
               } else if (_this.status !== 'historical') {
                  _localState.nestedTableState.sortedBy = 'finalizedDeliveryHq'
               } else {
                  var hasSomeActualDeliveryValues = _this.stops.some(function (stop) {
                     return stop.actualDelivery
                  })

                  if (hasSomeActualDeliveryValues) {
                     _localState.nestedTableState.sortedBy = 'actualDeliveryHq'
                  } else {
                     _localState.nestedTableState.sortedBy = 'finalizedDeliveryHq'
                  }
               }
            })
         },
         closeUi: function () {
            var _this = this
            _this.isTripUiExpanded = false
         },
         saveEdits: function () {
            var _this = this
            // Only proceed if there are one or more unsaved edits
            if (!_this.unsavedEdits.length) {
               return $q.resolve()
            }

            Object.keys(_this.display).forEach(function (key) {
               _this[key] = _this.display[key] && _this.display[key].isoString
            })

            return $q.resolve(logisticsData.updateTrip(_this))
         },
         delete: function () {
            var tripId = this.id
            $rootScope.blur()
            setBusyTrue('Deleting trip')
            return logisticsData
               .deleteTrip(tripId)
               .then(function () {
                  util.removeById(_logisticsRootState.route.trips, tripId)
                  alertService.successMessage('Trip deleted')
               })
               .catch(function (error) {
                  console.error(error)
                  alertService.errorMessage('Error deleting the trip. Error logged to console.')
               })
               .finally(setBusyFalse)
         },
         selectStopInUi: function (stop) {
            var isAlreadySelected = this.selectedStops.find(function (selectedStop) {
               return selectedStop.id === stop.id
            })
            if (!isAlreadySelected) {
               this.selectedStops.push(stop)
               if (stop.pickupId) {
                  this.selectedPickups.push(stop)
               }
               stop.selectedInUi = true
            }
         },
         deselectStopInUi: function (stop) {
            var indexToDeselect = this.selectedStops.findIndex(function (selectedStop) {
               return selectedStop.id === stop.id
            })
            this.selectedStops.splice(indexToDeselect, 1)

            if (stop.pickup) {
               var pickupIndexToDeselect = this.selectedPickups.findIndex(function (pickup) {
                  return pickup.id === stop.id
               })
               this.selectedPickups.splice(pickupIndexToDeselect, 1)
            }

            stop.selectedInUi = false

            if (this.truckloadsById[stop.truckloadId]) {
               this.truckloadsById[stop.truckloadId].allStopsSelectedInGroup = false
            }

            this.allStopsSelected = false
         },
         handleSelectChange: function (stop) {
            var selectedStopIds = this.selectedStops.map(function (selectedStop) {
               return selectedStop.id
            })
            if (selectedStopIds.includes(stop.id)) {
               this.deselectStopInUi(stop)
            } else {
               this.selectStopInUi(stop)
            }

            // Special handling for when the bulk adjust feature is open.
            if (_localState.uiShowBulkAdjust) {
               var selectedStopsForCurrentTruckload = this.selectedStops.filter(function (selectedStop) {
                  return selectedStop.truckloadId !== stop.truckloadId
               })
               // If all in the current truckload are selected
               if (this.selectedStops.length === selectedStopsForCurrentTruckload.length) {
                  setAdjustOffsetToFirst()
               }
               // Custom selection
               else {
                  setAdjustOffsetToCustom()
               }
            }
         },
         selectAllInGroup: function (groupId) {
            var _this = this
            if (groupId) {
               this.stopsByTruckloadId[groupId].forEach(function (stop) {
                  _this.selectStopInUi(stop)
               })
               // Ensure that the select all checkbox is checked (necessary when calling this programmatically)
               this.truckloadsById[groupId].allStopsSelectedInGroup = true
            } else {
               this.stops.forEach(function (stop) {
                  _this.selectStopInUi(stop)
               })
               this.allStopsSelected = true
            }
         },
         deselectAllInGroup: function (groupId) {
            var _this = this
            if (groupId) {
               this.stopsByTruckloadId[groupId].forEach(function (stop) {
                  _this.deselectStopInUi(stop)
               })
            } else {
               this.stops.forEach(function (stop) {
                  _this.deselectStopInUi(stop)
               })
               this.allStopsSelected = false
            }
         },
         handleSelectAllChange: function (maybeGroupId) {
            if (maybeGroupId) {
               if (this.truckloadsById[maybeGroupId].allStopsSelectedInGroup) {
                  this.selectAllInGroup(maybeGroupId)
               } else {
                  this.deselectAllInGroup(maybeGroupId)
               }

               // Special handling for when the bulk adjust feature is open.
               if (_localState.uiShowBulkAdjust) {
                  if (this.truckloadsById[maybeGroupId].allStopsSelectedInGroup) {
                     setAdjustOffsetToFirst()
                  } else {
                     setAdjustOffsetToCustom()
                  }
               }
            } else {
               if (this.allStopsSelected) {
                  this.selectAllInGroup()
               } else {
                  this.deselectAllInGroup()
               }

               // Special handling for when the bulk adjust feature is open.
               if (_localState.uiShowBulkAdjust) {
                  if (this.allStopsSelected) {
                     setAdjustOffsetToFirst()
                  } else {
                     setAdjustOffsetToCustom()
                  }
               }
            }
         },
         toggleSelectionOfAllStopsInAllGroups: function () {
            if (this.allStopsSelected) {
               this.selectAllStopsInAllGroups()
            } else {
               this.deselectAllStopsInAllGroups()
            }
         },
         selectAllStopsInAllGroups: function () {
            var _this = this
            var truckloadIds = Object.keys(this.truckloadsById)
            truckloadIds.forEach(function (truckloadId) {
               _this.selectAllInGroup(truckloadId)
            })
            this.allStopsSelected = true
         },
         deselectAllStopsInAllGroups: function () {
            var _this = this
            var truckloadIds = Object.keys(this.truckloadsById)
            if (truckloadIds && truckloadIds.length) {
               truckloadIds.forEach(function (truckloadId) {
                  _this.deselectAllInGroup(truckloadId)
               })
            } else {
               _this.deselectAllInGroup()
            }
            this.allStopsSelected = false
         },
      }

      function toTripView(trip) {
         var tripViewPrototype = angular.extend({}, _tripViewPrototype, _dateTimeHandlerPrototype)

         var tripView = angular.merge(Object.create(tripViewPrototype), trip)
         tripView.unsavedEdits = [] // filled with properties of edits when they are made
         tripView.selectedStops = []
         tripView.selectedPickups = []

         tripView.truckloadIds =
            trip.truckloads &&
            trip.truckloads.map(function (truckload) {
               return truckload.id.toString()
            })

         // Set the frontend-specific trip status (in contrast to the backend trip status returned by the API).
         //
         // Possible trip statuses from the API: `new`, `verified`, `sequenced`, `shipped`
         //
         // Note: The frontend has no way of knowing whether a trip shipped or was bumped. Having the API return
         // this is non-trivial and not a high priority, so we have to work with what we have. This is the reason
         // for the specific approach below used for setting the status.

         // Case: Future (pre verification)
         if (trip.status === 'new') {
            tripView.status = 'future'
            tripView.statusDisplay = 'Future'
            tripView.statusClass = 'is-future'
         }
         // Case: Verified or Sequenced (always displays as "Verified" to the user)
         else if (trip.status === 'verified' || trip.status === 'sequenced') {
            tripView.status = trip.status
            tripView.statusDisplay = 'Verified'
            tripView.statusClass = 'is-verifiedOrSequenced'
         }
         // Case: Shipped (historical or currently shipping)
         else if (trip.status === 'shipped') {
            // Case: Historical (delivery end is in the past)
            if (_luxonDT.fromISO(tripView['delivery-end']).diffNow().toObject().milliseconds <= 0) {
               tripView.status = 'historical'
               tripView.statusDisplay = 'Historical'
               tripView.statusClass = 'is-historical'
            }
            // Case: Currently Shipping
            else {
               tripView.status = 'currentlyShipping'
               tripView.statusDisplay = 'Currently Shipping'
               tripView.statusClass = 'is-currentlyShipping'
            }
         }

         // For future trips, get and set the trip's earliest stop that's a drop (not a pickup)
         // Note: We're not blocking the UI on this completing. The thinking is that this is
         // only for setting a `maxDate` property on a trip's Delivery Start date, which is
         // relevant only in the date edit UI. By the time a user clicks to open the UI, this
         // should have finished.
         if (tripView.status === 'future') {
            logisticsData.getTripsEarliestDrop(trip.id).then(function (earliestDrop) {
               tripView.earliestDrop = earliestDrop
            })
         }

         if (tripView.status === 'historical') {
            var daysAgo = Math.abs(_luxonDT.fromISO(tripView['delivery-end']).diffNow('days').days)
            if (tripView['delivery-end'] && daysAgo > _logisticsRootState.route['cutoff-frequency']) {
               tripView.deliveryEndOverCutoffFrequency = true
            }
         }

         tripView.createDisplayObject()

         if (tripView.id === parseInt(_logisticsRootState.params.trip)) {
            tripView.openUi()
         }

         return tripView
      }

      function toTripViews(trips) {
         return trips.map(toTripView)
      }

      function loadCurrentRouteTrips() {
         var currentRoute = util.findById(_logisticsRootState.routesAll, _logisticsRootState.params.route)

         setBusyTrue('Loading trips')

         return logisticsData
            .getTrips({
               route: currentRoute.name,
               'cutoff-before': _localState.tripFilterRange.cutoffBeforeDisplay,
               'cutoff-after': _localState.tripFilterRange.cutoffAfterDisplay,
               inline: 'truckloads',
            })
            .then(toTripViews)
            .then(function (tripViews) {
               currentRoute.trips = tripViews
               _logisticsRootState.route = currentRoute
            })
            .catch(function (error) {
               console.error(error)
               alertService.errorMessage('Error loading trips. Error logged to console.')
            })
            .finally(setBusyFalse)
      }

      $scope.resetAllTripChanges = function (bypassAlert) {
         _logisticsRootState.totalUnsavedTripEditsCount = 0
         if (!_logisticsRootState.route.trips) {
            return
         }

         closeNewDropUi()

         _logisticsRootState.route.trips.forEach(function (trip) {
            if (trip.unsavedEdits.length) {
               trip.dateCellResetMultiple()
            }
         })

         if (_localState.currentTrip) {
            _localState.currentTrip.deselectAllStopsInAllGroups()
         }

         if (!bypassAlert) {
            alertService.successMessage('Trip change(s) reset')
         }
      }

      $scope.saveAllTripChanges = function () {
         setBusyTrue('Saving trip change(s)')

         var promises = _logisticsRootState.route.trips.map(function (trip) {
            return trip.saveEdits()
         })

         var maybeFailed
         $q.allSettled(promises)
            .then(function (results) {
               // Capture any potential failures
               maybeFailed = captureAllSettledErrors(results)
            })
            .then(loadCurrentRouteTrips)
            .then(updateTripEditsCount)
            .then(function () {
               // Maybe handle error(s)
               if (maybeFailed.length) {
                  var failedTripIds = maybeFailed.map(function (failureResponse) {
                     console.error(failureResponse)
                     return failureResponse.reason.resource.id
                  })
                  alertService.errorMessage(
                     maybeFailed.length +
                        ' trip(s) had errors updating. The errors have been logged to the console.<br>ID(s) of the trip(s) not saved: ' +
                        failedTripIds.join(', ')
                  )
               }
               // Otherwise, no errors
               else {
                  alertService.successMessage('Trip change(s) saved')
               }

               setBusyFalse()
            })
      }

      function updateTripEditsCount() {
         if (!_logisticsRootState.route || !_logisticsRootState.route.trips) {
            return
         }

         _logisticsRootState.totalUnsavedTripEditsCount = _logisticsRootState.route.trips.reduce(function (
            accumulator,
            item
         ) {
            return accumulator + item.unsavedEdits.length
         },
         0)
      }

      function updateStopEditsCount() {
         if (!_logisticsRootState.route || !_logisticsRootState.params.trip) {
            return
         }

         var editingTrip = _localState.currentTrip
         if (!editingTrip || !editingTrip.stops) {
            return
         }

         _logisticsRootState.totalUnsavedStopEditsCount = editingTrip.stops.reduce(function (accumulator, item) {
            return accumulator + item.unsavedEdits.length
         }, 0)
      }

      $scope.isNoTruck = function (truckloadId) {
         return truckloadId === '0'
      }

      $scope.shouldShowCarrierInfoEditButton = function (truckloadId) {
         // The "no truck" case should always return false.
         if ($scope.isNoTruck(truckloadId)) {
            return false
         }

         var truckload = _localState.currentTrip.truckloadsById[truckloadId]
         return (
            _localState.currentTrip.status !== 'historical' ||
            // If is carrier shuttle and the relevant pay state is not locked
            (truckload && truckload.isCarrierShuttle && !truckload.carrierShuttlePayStateLocked) ||
            // If carrier type is "contractor" and the relevant pay state is not locked
            (truckload && truckload.carrierTypeId === 3 && !truckload.carrierContractorPayStateLocked)
         )
      }

      $scope.showCarrierFormModal = function (truckloadId) {
         $uibModal.open({
            templateUrl: 'modules/logistics/views/modal.truckCarrierForm.html',
            size: 'md',
            backdrop: 'static', // do not allow closing by clicking off the modal
            keyboard: false, // do not allow closing via the escape key
            controller: 'ModalTruckCarrierFormController',
            resolve: {
               carrierTypeOptions: function () {
                  return _carrierTypeOptions
               },
               logisticsRootState: function () {
                  return _logisticsRootState
               },
               currentTrip: function () {
                  return _localState.currentTrip
               },
               truckloadId: function () {
                  return truckloadId
               },
               setBusyTrue: function () {
                  return setBusyTrue
               },
               setBusyFalse: function () {
                  return setBusyFalse
               },
               existingCarrierData: function () {
                  return _localState.currentTrip.truckloadsById[truckloadId]
               },
               onSubmitSuccess: function () {
                  return function (updatedTruckload) {
                     _localState.currentTrip.truckloadsById[updatedTruckload.id] = toTruckloadView(updatedTruckload)
                  }
               },
            },
         })
      }

      function refreshTruckload(truckloadId) {
         return logisticsData
            .getTruckloadById(truckloadId)
            .then(function (updatedTruckload) {
               _localState.currentTrip.truckloadsById[truckloadId] = toTruckloadView(updatedTruckload)
            })
            .catch(function (error) {
               console.error(error)
               alertService.errorMessage('Error refreshing truckload. Error logged to console.')
            })
      }

      $scope.showCarrierPoCreationModal = function (truckloadId) {
         $uibModal.open({
            templateUrl: 'modules/modals/modal.promptAndDo.html',
            size: 'md',
            backdrop: 'static', // do not allow closing by clicking off the modal
            keyboard: false, // do not allow closing via the escape key
            controller: function ($scope) {
               $scope.truckloadId = truckloadId
               $scope.message = 'Are you sure you want to create the Carrier POs for this truckload?'
               $scope.maybeConfirmBtnText = 'Yes, create POs'
               $scope.busyMessage = 'Creating'
               $scope.modalState = {
                  isBusy: false,
                  showSuccess: false,
               }
               $scope.handleSubmit = function () {
                  $scope.modalState.isBusy = true
                  logisticsData
                     .createCarrierPos(truckloadId)
                     .then(function () {
                        refreshTruckload(truckloadId)
                        $scope.modalState.showSuccess = true
                     })
                     .catch(function (error) {
                        console.error(error)
                        alertService.errorMessage('Error creating Carrier POs. Error logged to console.')
                     })
                     .finally(function () {
                        $scope.modalState.isBusy = false
                     })
               }
            },
         })
      }

      //================================================================================
      // Stops (core handling)
      //================================================================================

      function shouldPersistStopChange() {
         // Never persist if the trip is on a barge line (a "Y" route)
         if (_logisticsRootState.route.name.startsWith('Y') && _logisticsRootState.route.name.length === 2) {
            return false
         }

         return _logisticsRootState.statusOfToggledOpenTrip === 'future' && _logisticsRootState.shouldPersistStopChanges
      }

      var _stopViewPrototype = {
         createDisplayObject: function () {
            var display = {}
            display.estimatedDelivery = setTimeDisplayObject(this.estimatedDelivery, this.timezone)

            if (this.finalizedDelivery) {
               display.finalizedDelivery = setTimeDisplayObject(this.finalizedDelivery, this.timezone)
            } else {
               display.finalizedDelivery = setTimeDisplayObject(this.estimatedDelivery, this.timezone)
               display.finalizedDelivery.needsToBeSet = true
            }

            if (this.actualDelivery) {
               display.actualDelivery = setTimeDisplayObject(this.actualDelivery, this.timezone)
            } else {
               display.actualDelivery = setTimeDisplayObject(this.estimatedDelivery, this.timezone)
               display.actualDelivery.needsToBeSet = true
            }

            if (this.deliveryCompletedAt) {
               display.deliveryCompletedAt = setTimeDisplayObject(this.deliveryCompletedAt, this.timezone)
            } else {
               display.deliveryCompletedAt = setTimeDisplayObject(
                  this.actualDelivery || this.estimatedDelivery,
                  this.timezone
               )
               display.deliveryCompletedAt.needsToBeSet = true
            }

            this.display = angular.extend({}, display)
         },
         delete: function (trip) {
            var _this = this
            $rootScope.blur()
            setBusyTrue('Deleting stop')
            return logisticsData
               .deleteStop({
                  id: _this.id,
                  persist: shouldPersistStopChange(),
               })
               .then(function () {
                  trip.stops = trip.stops.filter(function (stop) {
                     return stop.id !== _this.id
                  })

                  updateStopsOnStopDelete(trip.stops)

                  alertService.successMessage('Stop deleted')
               })
               .catch(function (error) {
                  console.error(error)
                  alertService.errorMessage('Error deleting stop. Error logged to console.')
               })
               .finally(setBusyFalse)
         },
         equalsAdjacent: function (toProp, fromProp) {
            return this.display[fromProp].isoString === this.display[toProp].isoString
         },
         copyFromAdjacent: function (toProp, fromProp) {
            if (
               this.display[toProp].needsToBeSet ||
               this.display[fromProp].isoString !== this.display[toProp].isoString
            ) {
               this.display[toProp] = setTimeDisplayObject(this.display[fromProp].isoString, this.timezone)
               if (!this.unsavedEdits.includes(toProp)) {
                  this.unsavedEdits.push(toProp)
               }
               this.display[toProp].needsToBeSet = false
               this.updateEditsCount()
            }
         },
      }

      function getStopDropOrPickup(stop) {
         var dropOrPickupPromise

         if (stop.drop) {
            var maybeDropById = util.findById(_logisticsRootState.route.drops, stop.drop)
            if (maybeDropById) {
               dropOrPickupPromise = angular.copy(maybeDropById)
            } else {
               dropOrPickupPromise = logisticsData.getDropById(stop.drop)
            }
         }

         if (stop.pickup) {
            var pickupById = util.findById(_logisticsRootState.route.pickups, stop.pickup)
            dropOrPickupPromise = angular.copy(pickupById)
         }

         return $q.resolve(dropOrPickupPromise).catch(function (error) {
            console.error(error)
         })
      }

      function toStopView(stop) {
         return getStopDropOrPickup(stop).then(function (dropOrPickup) {
            if (stop.drop) {
               stop.drop = dropOrPickup
            } else if (stop.pickup) {
               stop.pickup = dropOrPickup
            }

            var stopViewPrototype = angular.extend({}, _stopViewPrototype, _dateTimeHandlerPrototype)

            var stopView = angular.merge(Object.create(stopViewPrototype), stop)

            if (stopView.drop) {
               var thisStopsOrderFrequencyInstance = stopView.drop['order-frequency'].find(function (item) {
                  return item.cutoff === _localState.currentTrip.cutoff.slice(0, 10)
               })
               stopView.orderCount = thisStopsOrderFrequencyInstance && thisStopsOrderFrequencyInstance.orders
            }

            stopView.truckloadId = (stop.truckload && stop.truckload.id) || idIfNoTruckload // truckloadId of `0` is for "No Truck"
            stopView.truckNumber = stop.truckload && stop.truckload.truckNumber

            stopView.estimatedDeliveryHq = convertToIsoInHqTimezone(stop.estimatedDelivery)
            if (stop.finalizedDelivery) {
               stopView.finalizedDeliveryHq = convertToIsoInHqTimezone(stop.finalizedDelivery)
            }
            if (stop.actualDelivery) {
               stopView.actualDeliveryHq = convertToIsoInHqTimezone(stop.actualDelivery)
            }

            stopView.unsavedEdits = [] // filled with properties of edits when they are made
            stopView.selectedInUi = false

            stopView.createDisplayObject()

            return $q.resolve(stopView)
         })
      }

      function toStopViews(stops) {
         return $q.map(stops, toStopView)
      }

      function loadTripStops(trip, isInitialLoad) {
         function getAllRoutePickups(routeId) {
            // Do not load pickups if they've already been loaded.
            var routeOfPickupsToLoad = util.findById(_logisticsRootState.routesAll, routeId)

            if (routeOfPickupsToLoad && routeOfPickupsToLoad.pickups && routeOfPickupsToLoad.pickups.length) {
               return $q.resolve()
            }

            return logisticsData.getAllRoutePickups(_logisticsRootState.route).then(function (pickups) {
               _logisticsRootState.route.pickups = pickups
            })
         }

         if (isInitialLoad) {
            _localState.loadingTripStops = true
         }

         var promises = [
            logisticsData.getAllTripStops(trip.id),
            _logisticsRootState.loadRouteDrops(_logisticsRootState.params.route),
            getAllRoutePickups(_logisticsRootState.params.route),
         ]

         return $q
            .all(promises)
            .then(function (results) {
               _localState.currentTrip = util.findById(
                  _logisticsRootState.route.trips,
                  parseInt(_logisticsRootState.params.trip)
               )
               return toStopViews(results[0])
            })
            .then(function (stopViews) {
               // Set whether the trip has any stops with missing finalized delivery times.
               trip.hasOneOrMoreStopsWithMissingEstimatedDeliveryTimes = stopViews.some(function (stopView) {
                  return !stopView.finalizedDelivery
               })

               // Set whether the trip has any stops with missing actual delivery times.
               trip.hasOneOrMoreStopsWithMissingActualDeliveryTimes = stopViews.some(function (stopView) {
                  return !stopView.time
               })

               trip.stops = stopViews

               groupStopsByTruckload(trip)
            })
            .catch(function (err) {
               console.error(err)
               alertService.errorMessage('Error loading stops. Error logged to console.')
            })
            .finally(function () {
               if (isInitialLoad) {
                  _localState.loadingTripStops = false
               }
            })
      }

      function getTruckCarrierPersonData(key, personId) {
         return _logisticsRootState.truckloadPeopleByType[key].find(function (entry) {
            return entry.id === personId
         })
      }

      function groupStopsByTruckload(trip) {
         trip.isUiGroupedByTruckload = trip.status !== 'new' && trip.status !== 'future'

         var stopsByTruckloadId = util.groupByProperty(trip.stops, 'truckloadId')

         // This is used elsewhere as a convenient approach to being able to easily get stops by truckload ID.
         trip.stopsByTruckloadId = stopsByTruckloadId

         // Here we set `trip.truckloadIdAndStops` to an array of arrays, where each inner array is a truckload ID and its stops.
         //
         // Using an array here is necessary to preserve the sort order, as object properties are not guaranteed to be in order (and
         // AngularJS unfortunately doesn't support iterating over the native JS `Map` type).

         if (!trip.isUiGroupedByTruckload) {
            trip.truckloadIdAndStops = [[undefined, trip.stops]]
         } else {
            var trucks = Object.keys(stopsByTruckloadId).map(function (truckloadId) {
               var truckload = stopsByTruckloadId[truckloadId][0].truckload || {}
               return {
                  truckloadId: truckloadId,
                  truckNumber: truckload.truckNumber,
               }
            })

            util.sortByProperty('truckNumber', trucks)

            trip.truckloadIdAndStops = trucks.map(function (item) {
               return [item.truckloadId, stopsByTruckloadId[item.truckloadId]]
            })
         }

         //

         trip.truckloadsById = trip.truckloadIds.reduce(function (accumulator, truckloadId) {
            if (!accumulator[truckloadId]) {
               var truckload = stopsByTruckloadId[truckloadId][0].truckload || {}

               accumulator[truckloadId] = toTruckloadView(truckload)
            }
            return accumulator
         }, {})
      }

      function toTruckloadView(truckload) {
         truckload.driverPersonEntry =
            truckload.truckDriverPersonId && getTruckCarrierPersonData('truckDrivers', truckload.truckDriverPersonId)

         truckload.carrierContractorPersonEntry =
            truckload.carrierContractorPersonId &&
            getTruckCarrierPersonData('carrierContractors', truckload.carrierContractorPersonId)

         truckload.carrierShuttlePersonEntry =
            truckload.carrierShuttlePersonId &&
            getTruckCarrierPersonData('carrierShuttles', truckload.carrierShuttlePersonId)

         return truckload
      }

      function updateStopsOnStopDelete(nonDeletedStops) {
         _localState.currentTrip.stops = nonDeletedStops
         groupStopsByTruckload(_localState.currentTrip)
         _localState.currentTrip.deselectAllStopsInAllGroups()
      }

      $scope.deleteAllSelectedStops = function () {
         setBusyTrue('Deleting stops')

         var trip = _localState.currentTrip
         var stopsNotToDelete = trip.stops.filter(function (stop) {
            return !stop.selectedInUi
         })
         var stopsToDelete = trip.stops.filter(function (stop) {
            return stop.selectedInUi
         })
         var promises = stopsToDelete.map(function (stop) {
            return logisticsData.deleteStop({
               id: stop.id,
            })
         })

         return $q
            .all(promises)
            .then(function () {
               trip.stops = stopsNotToDelete

               updateStopsOnStopDelete(trip.stops)

               alertService.successMessage(promises.length + ' stops successfully deleted')
            })
            .catch(function (error) {
               console.error(error)
               alertService.errorMessage('Error deleting stops. Error logged to console.')
            })
            .finally(setBusyFalse)
      }

      $scope.showFlagFinalizedDeliveryModal = function () {
         $scope.$uibModalInstance = $uibModal.open({
            templateUrl: 'modules/logistics/views/modal.flagFinalizedDelivery.html',
            size: 'md',
            controller: function ($scope) {
               var _modalState = {
                  selectedValue: undefined,
                  stops: _localState.currentTrip.selectedStops,
               }
               $scope.modalState = _modalState
               $scope.logisticsRootState = _logisticsRootState

               function updateStop(stop) {
                  var updateData = {
                     id: stop.id,
                     finalizedDeliveryFlag: _modalState.selectedValue === 'unflag' ? null : _modalState.selectedValue,
                  }
                  return logisticsData.updateStop({}, updateData)
               }

               $scope.submit = function () {
                  _modalState.busy = true
                  var promises = _modalState.stops.map(function (pickup) {
                     return updateStop(pickup)
                  })
                  return $q
                     .all(promises)
                     .then(function (updatedStops) {
                        _localState.currentTrip.deselectAllStopsInAllGroups()

                        // Update each stop
                        updatedStops.forEach(function (updatedStop) {
                           var existingStopIndex = _localState.currentTrip.stops.findIndex(function (stop) {
                              return stop.id === updatedStop.id
                           })
                           if (existingStopIndex !== -1) {
                              _localState.currentTrip.stops[existingStopIndex].finalizedDeliveryFlag =
                                 updatedStop.finalizedDeliveryFlag
                           }
                        })

                        alertService.successMessage('Change(s) successful')
                     })
                     .catch(function () {
                        _modalState.busy = false
                     })
               }
            },
         })
      }

      $scope.changeTruck = function () {
         $scope.$uibModalInstance = $uibModal.open({
            templateUrl: 'modules/logistics/views/modal.changeTruck.html',
            size: 'md',
            controller: function ($scope) {
               var _modalState = {
                  toTruckloadId: undefined,
                  selectedPickups: _localState.currentTrip.selectedPickups,
                  selectedDropCount:
                     _localState.currentTrip.selectedStops.length - _localState.currentTrip.selectedPickups.length,
                  get toTuckloadOptions() {
                     var options
                     options = _localState.currentTrip.truckloads
                     var optionsSorted = util.sortByProperty('truckNumber', options)
                     return optionsSorted
                  },
               }
               $scope.modalState = _modalState
               $scope.logisticsRootState = _logisticsRootState

               function updateStop(stop) {
                  var updateData = {
                     id: stop.id,
                     truckloadId: _modalState.toTruckloadId ? parseInt(_modalState.toTruckloadId) : null,
                  }
                  return logisticsData.updateStop({}, updateData)
               }

               $scope.submit = function () {
                  _modalState.busy = true
                  var promises = _modalState.selectedPickups.map(function (pickup) {
                     return updateStop(pickup)
                  })
                  return $q
                     .all(promises)
                     .then(function (updatedStops) {
                        _localState.currentTrip.deselectAllStopsInAllGroups()

                        // Update the truckload of each stop (pickup, in this case).
                        updatedStops.forEach(function (updatedStop) {
                           var existingStopIndex = _localState.currentTrip.stops.findIndex(function (stop) {
                              return stop.id === updatedStop.id
                           })
                           if (existingStopIndex !== -1) {
                              if (updatedStop.truckload) {
                                 _localState.currentTrip.stops[existingStopIndex].truckload = updatedStop.truckload
                                 _localState.currentTrip.stops[existingStopIndex].truckloadId = updatedStop.truckload.id
                              } else {
                                 _localState.currentTrip.stops[existingStopIndex].truckloadId = idIfNoTruckload
                              }
                           }
                        })
                        // Re-group them with their updated stops
                        groupStopsByTruckload(_localState.currentTrip)

                        alertService.successMessage('Truckload change(s) successful')
                     })
                     .catch(function (errorResponse) {
                        _modalState.busy = false
                        _modalState.errorData = errorResponse.data.error
                     })
               }
            },
         })
      }

      $scope.resetAllStopChanges = function () {
         _logisticsRootState.totalUnsavedStopEditsCount = 0

         var editingTrip = _localState.currentTrip
         if (!editingTrip || !editingTrip.stops) {
            return
         }
         editingTrip.stops.forEach(function (stop) {
            if (stop.unsavedEdits.length) {
               stop.dateCellResetMultiple()
            }
         })
      }

      function saveStopChanges(stop) {
         // Only proceed if there are one or more unsaved edits
         if (!stop.unsavedEdits.length) {
            return $q.resolve()
         }

         var updateData = {
            id: stop.id,
         }

         stop.unsavedEdits.forEach(function (key) {
            if (stop.display[key]) {
               if (!stop.display[key].needsToBeSet) {
                  updateData[key] = stop.display[key].isoString
               } else if (_clearablePropsWhitelist.includes(key)) {
                  // This effectively clears any existing value
                  updateData[key] = null
               }
            }
         })

         return logisticsData.updateStop({persist: shouldPersistStopChange()}, updateData)
      }

      $scope.saveAllStopChanges = function () {
         setBusyTrue('Saving changes to one or more stops')

         var stopsToSave = _localState.currentTrip.stops.filter(function (stop) {
            return stop.unsavedEdits.length
         })

         var saveStopChangesFunctions = stopsToSave.map(function (stop) {
            return saveStopChanges.bind(null, stop)
         })

         var maybeFailed
         var maybeFulfilled

         return util
            .promiseSequence(saveStopChangesFunctions)
            .then(function (results) {
               maybeFailed = captureAllSettledErrors(results)
               maybeFulfilled = results.filter(function (result) {
                  return result.state === 'fulfilled'
               })

               // Update the trip's `earliestDrop` (if it's a future trip)
               // This is to prevent caching bugs, as some datepickers conditionally disable dates based on the `earliestDrop`
               if (_localState.currentTrip.status === 'future') {
                  logisticsData.getTripsEarliestDrop(_localState.currentTrip.id).then(function (earliestDrop) {
                     _localState.currentTrip.earliestDrop = earliestDrop
                  })
               }

               return loadTripStops(_localState.currentTrip)
            })
            .then(updateStopEditsCount)
            .then(function () {
               var hasFulfilled = maybeFulfilled && maybeFulfilled.length

               // Error message
               if (maybeFailed && maybeFailed.length) {
                  // Success/Info message (when also error(s))
                  if (hasFulfilled) {
                     alertService.infoMessage(
                        maybeFulfilled.length + ' of ' + stopsToSave.length + ' stop change(s) saved'
                     )
                  }

                  var failedStopIds = maybeFailed.map(function (failureResponse) {
                     return failureResponse.reason.resource.id
                  })
                  var failedStopDropNamesDisplay = failedStopIds
                     .map(function (stopId) {
                        return '– ' + util.findById(_localState.currentTrip.stops, stopId).drop.name
                     })
                     .join('<br>')
                  alertService.errorMessage(
                     maybeFailed.length +
                        ' stop(s) had errors saving. The errors have been logged to the console.<br><br>The drop(s) not saved:<br><br>' +
                        failedStopDropNamesDisplay
                  )
               }
               // Success message (when no error(s))
               else if (hasFulfilled) {
                  alertService.successMessage('Stop change(s) saved')
               }

               setBusyFalse()
            })
      }

      $scope.copyAllFromAdjacent = function (stops, toProp, fromProp) {
         stops.forEach(function (stop) {
            stop.copyFromAdjacent(toProp, fromProp)
         })
         updateStopEditsCount()
      }

      //================================================================================
      // New Stop UI
      //================================================================================

      $scope.showNewStopUi = function (args) {
         // args = {
         //    trip: Obj
         //    selectableList: Array
         //    groupId: Int
         // }

         function getFurthestOutDatetime(zone) {
            // Note: If there are not yet any stops assigned to the trip (which is the case for
            // new routes), then `args.selectableList` will be `undefined`.

            var finalizedDeliveryTimestamps =
               args.selectableList && util.filterUndefined(util.mapToProp(args.selectableList, 'finalizedDelivery'))
            var estimatedDeliveryTimestamps =
               args.selectableList && util.filterUndefined(util.mapToProp(args.selectableList, 'estimatedDelivery'))

            // Base off the finalized if available, falling back to estimated.
            // Another possible approach here is to use the trip's status (e.g. always using finalized if
            // the trip is not future), but this won't work because some non-future trips don't have any finalized
            // values (in the case of drivers who don't give the Logistic's team a schedule, which apparently happens).
            var timestampsForSearch =
               finalizedDeliveryTimestamps && finalizedDeliveryTimestamps.length
                  ? finalizedDeliveryTimestamps
                  : estimatedDeliveryTimestamps
            if (timestampsForSearch && timestampsForSearch.length) {
               return _luxonDT
                  .fromISO(timestampsForSearch[timestampsForSearch.length - 1], {zone: zone})
                  .set({seconds: 0, milliseconds: 0})
                  .plus({hours: 1})
                  .toJSDate()
            } else {
               // If trying to set the date inteligently fails, fallback to one day from now.
               return _luxonDT
                  .fromJSDate(new Date(), {zone: zone})
                  .set({seconds: 0, milliseconds: 0})
                  .plus({days: 1})
                  .toJSDate()
            }
         }

         function getDropsAvailableToAdd() {
            var dropsAlreadyOnTrip = args.trip.stops.map(function (stop) {
               return stop.drop && stop.drop.id
            })

            return util.sortByProperty(
               'name',
               _logisticsRootState.route.dropsActive.filter(function (drop) {
                  return !dropsAlreadyOnTrip.includes(drop.id)
               })
            )
         }

         var newStopPrototype = {
            uiDatepickerOpen: false,
            dropsAvailableToAdd: getDropsAvailableToAdd(),
            truckloadId: args.groupId === idIfNoTruckload.toString() ? null : parseInt(args.groupId),
            get timezone() {
               return (this.dropsAvailableToAdd[0] && this.dropsAvailableToAdd[0].timezone) || 'America/Los_Angeles'
            },
            get selectedDropToAdd() {
               return this.dropsAvailableToAdd[0] // Sets the initial value to the first in the list.
            },
            get arrivalBy() {
               return getFurthestOutDatetime(this.timezone)
            },
            get timezoneShort() {
               return this.getTimezoneShort()
            },
            get timezoneOffset() {
               return this.getTimezoneOffset()
            },
            getTimezoneShort: function () {
               return _luxonDT.fromJSDate(this.arrivalBy, {
                  zone: this.timezone,
               }).offsetNameShort
            },
            getTimezoneOffset: function () {
               if (!this.arrivalBy) {
                  return
               }
               var dateToSetAsIso = _luxonDT.fromJSDate(this.arrivalBy, {zone: this.timezone}).toISO()
               return dateToSetAsIso.substr(dateToSetAsIso.length - 6) // ISO timezone offset value (e.g. `-08:00`)
            },
            updateTimezoneValues: function () {
               this.timezoneOffset = this.getTimezoneOffset()
               this.timezoneShort = this.getTimezoneShort()
            },
            onStopSelectChange: function () {
               this.timezone = this.selectedDropToAdd.timezone || 'America/Los_Angeles'
               this.updateTimezoneValues()
            },
            closeUi: function () {
               closeNewDropUi()
            },
         }

         args.trip.newStop = angular.extend({}, newStopPrototype)

         _localState.newStopUiForTruckloadId = args.groupId
         _localState.isNewStopUiOpen = true
      }

      $scope.createNewStop = function (trip) {
         setBusyTrue()

         var newStop = angular.extend({}, trip.newStop)

         var updateOptions = {
            persist: shouldPersistStopChange(),
         }

         var updateData = {
            trip: trip.id,
            estimatedDelivery: _luxonDT
               .fromJSDate(newStop.arrivalBy, {zone: newStop.timezone})
               .set({seconds: 0, milliseconds: 0})
               .toISO(),
         }

         if (trip.status !== 'future') {
            updateData.finalizedDelivery = updateData.estimatedDelivery
         }

         updateData.drop = newStop.selectedDropToAdd.id

         return logisticsData
            .createStop(updateOptions, updateData)
            .then(function (newStopResponse) {
               // The `stop` API doesn't yet support creating a new stop on a specific truckload.
               // Until this is supported (if ever), this updates the stop just after it's created to put it on the right
               // drop (if it was created from the UI on a specific truckload, in which case it will have a `truckloadId`).

               // Only update the stop's truckload if it wasn't created in the "no truckload" group.
               if (newStopResponse.drop && newStop.truckloadId !== idIfNoTruckload.toString() && newStop.truckloadId) {
                  return logisticsData.updateStop(
                     {},
                     {
                        id: newStopResponse.id,
                        truckloadId: newStop.truckloadId,
                     }
                  )
               } else {
                  return $q.resolve()
               }
            })
            .then(function () {
               newStop.closeUi()
               return loadTripStops(trip)
            })
            .then(function () {
               alertService.successMessage('Added drop')
            })
            .catch(function (error) {
               console.error(error)
               alertService.errorMessage('Error adding drop. Error logged to console.')
            })
            .finally(setBusyFalse)
      }

      function closeNewDropUi() {
         _localState.isNewStopUiOpen = false
      }

      //================================================================================
      // Add New Trips
      //================================================================================

      function scaffoldNewTrips() {
         if (_localState.newTripsToAdd.length) {
            return
         }

         function getFutureDate(daysIntoFuture, key) {
            function getCurrentLongestOutCreatedTrip() {
               // Returns the trip with the longest out (i.e. shipping longest time from now) from existing trips
               _logisticsRootState.route.trips.sort(function (a, b) {
                  return a.cutoff < b.cutoff
               })
               return _logisticsRootState.route.trips[0]
            }
            var maybeFurthestOutTrip = getCurrentLongestOutCreatedTrip()
            var tmpDate
            if (maybeFurthestOutTrip) {
               tmpDate = new Date(maybeFurthestOutTrip[key])
            } else {
               tmpDate = new Date()
            }
            var toSet = tmpDate.setDate(tmpDate.getDate() + daysIntoFuture)
            return new Date(toSet).toISOString()
         }

         var maxTripsToCreate = 12
         var frequency = _logisticsRootState.route['cutoff-frequency']
         var numberOfPossibleTripsIn120Days = Math.floor(120 / frequency)
         var numberOfTripsToCreate = Math.min(numberOfPossibleTripsIn120Days, maxTripsToCreate)

         function createTrip(trip, index) {
            trip = {
               display: {},
            }

            var timeProperties = ['cutoff', 'pick-date', 'delivery-start', 'delivery-end', 'warehouse-arrival']
            timeProperties.forEach(function (property) {
               trip[property] = getFutureDate(frequency * (index + 1), property)
               trip.display[property] = setTimeDisplayObject(trip[property])
            })

            return toTripView(trip)
         }

         _localState.newTripsToAdd = Array(numberOfTripsToCreate).fill().map(createTrip)
      }

      $scope.showAddTripsUi = function () {
         if (!!_localState.tripFilterRange.cutoffBeforeDisplay) {
            $window.alert(
               'Before you may add new trips, please update the trip filter to "All Future" (then try again)'
            )
         } else {
            $scope.resetAddTrips()
            _localState.showingAddTripUi = true
            $window.setTimeout(function () {
               document.getElementById('js-newTripsUi').scrollIntoView()
            })
         }
      }

      $scope.deleteAddTripsTrip = function (rowIndex) {
         _localState.newTripsToAdd.splice(rowIndex, 1)
      }

      $scope.cancelAddTrips = function () {
         _localState.newTripsToAdd = []
         _localState.showingAddTripUi = false
      }

      $scope.resetAddTrips = function () {
         _localState.newTripsToAdd = []
         scaffoldNewTrips()
      }

      $scope.saveAddTrips = function () {
         setBusyTrue('Adding new trip(s)')

         var tripsToAdd = _localState.newTripsToAdd.map(function (newTrip) {
            for (var k in newTrip.display) {
               if (typeof newTrip.display[k] === 'object') {
                  newTrip[k] = newTrip.display[k].isoString
               }
            }
            newTrip['backhaul-start'] = newTrip['delivery-start']
            newTrip['backhaul-end'] = newTrip['delivery-end']
            newTrip.route = _logisticsRootState.route.name
            return newTrip
         })

         var promises = tripsToAdd.map(logisticsData.createTrip)
         $q.all(promises)
            // Load the new trips. Currently the only way to do this is by getting all of them.
            // Optimization: Create a `refreshTrips` function that wouldn't have to fetch them all.
            .then(loadCurrentRouteTrips)
            .then(function () {
               alertService.successMessage('New trip(s) added')
            })
            .catch(function (error) {
               console.error(error)
               alertService.errorMessage('Error creating new trip(s). Error logged to console.')
            })
            .finally(function () {
               _localState.showingAddTripUi = false
               setBusyFalse()
            })
      }

      //================================================================================
      // Init
      //================================================================================

      function init() {
         loadCurrentRouteTrips()
      }

      init()

      //================================================================================
      // Events & Watchers
      //================================================================================

      $scope.$on('paramsChanged', function (event, toParams, fromParams) {
         if (!_logisticsRootState) {
            return
         }

         var activeRoute = util.findById(_logisticsRootState.routesAll, toParams.route)
         var isChangingRoutes = fromParams.route !== toParams.route

         if (isChangingRoutes) {
            loadCurrentRouteTrips()
            $scope.cancelAddTrips()
         } else {
            var isClosingTripUi = fromParams.trip && !toParams.trip && fromParams.route === toParams.route
            var isOpeningTripUi = !isClosingTripUi && fromParams.trip !== toParams.trip
            var isTogglingTripUis = fromParams.trip && toParams.trip && fromParams.trip !== toParams.trip

            // Note: IDs are integers from the API

            // Close trip UI
            if (isTogglingTripUis || isClosingTripUi) {
               util.findById(activeRoute.trips, fromParams.trip).closeUi()
            }
            // Open trip UI
            if (isTogglingTripUis || isOpeningTripUi) {
               util.findById(activeRoute.trips, toParams.trip).openUi()
            }
         }

         // Reset temporary state
         $scope.resetAllTripChanges(true)
         $scope.resetAllStopChanges()
         if (_localState.currentTrip) {
            _localState.currentTrip.selectedStops = []
            _localState.currentTrip.isUiGroupedByTruckload = false
         }
      })
   }
})()
